.projectdetails {
  margin-left: 10px;
  margin-right: 10px;

  h5 {
    margin-bottom: 10px;
  }
  .MuiGrid-root {
    font-size: 15px;
    height: 30px;
    p {
      margin: 0;
    }
  }
  .projectTabs {
    min-height: 30px;
    height: 40px;
    button#simple-tab-0,
    button#simple-tab-1 {
      font-size: 12px;
      padding: 0;
      margin-right: 25px;
    }
    button#simple-tab-0.Mui-selected,
    button#simple-tab-1.Mui-selected {
      color: #00895f;
    }
  }

  .project-master {
    .alignright {
      .exportBtn {
        float: right;
        margin-top: -5px;
        background-color: white;
        border: 1px solid white;
        padding: 0;
        margin-left: 10px;
      }
    }
    .project-treetable {
      table.p-treetable-table {
        min-width: 0px !important;
        font-size: 12px !important;
        .p-treetable-thead th {
          text-align: center;
          background-color: #00895f;
          color: white;
          font-family: Poppins;
          font-size: 0.875rem;
          svg.p-icon.p-sortable-column-icon {
            color: white;
          }
        }
        .p-treetable-thead th.w-10rem {
          width: 240px;
        }
        .p-treetable-tbody {
          td {
            text-align: center;
            height: 50px;
            padding: 0px;
          }
          .areacode {
            display: flex;
            align-items: center;
            button {
              height: auto;
            }
          }
        }
      }
    }
    .p-datatable .p-datatable-thead > tr > th {
      background-color: #00895f;
      color: white;
      font-family: Poppins;
      font-size: 0.875rem;
    }

    .p-datatable .p-sortable-column .p-sortable-column-icon {
      color: white;
    }
  }
}

.fieldParent {
  display: grid;
  margin-bottom: 16px;
}

.image-dialog-box {
  display: flex;
}

.deleteDialog {
  .action-buttons {
    float: right;
    .p-button {
      background-color: #ef4444;
      border: 1px solid #ef4444;
      color: white;
      margin-right: 10px;
    }
  }
}

.alertDialog {
  margin-bottom: 10px;
}

.wasteRecTab {
  .p-datatable-tbody {
    td {
      font-size: 13px;
    }
    .imageLink {
      cursor: pointer;
      display: flex;
    }
  }

  .actionBtn {
    text-align: end;
    margin-bottom: 15px;
  }
}

.card {
  .exportBtn {
    float: right;
    margin-top: -50px;
    background-color: white;
    border: 1px solid white;
    padding: 0;
  }
  .surveyRecordTable {
    margin-top: 60px;
  }
}

.wasteRecTab {
  .exportBtn {
    float: right;
    margin-top: -5px;
    background-color: white;
    border: 1px solid white;
    padding: 0;
  }
}
