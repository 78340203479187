.header {
  background-color: white !important;

  .navbar-brand-box{
    margin-left: -8px;
  }

  .logo {
    display: flex;
    text-decoration: none;

    span {
      margin-top: 1px;
      font-size: 20px;
    }
  }

  .header-toolbar {
    place-content: space-between;
  }

  .rightside {
    display: flex;
  }

  .languageSection {
    margin-right: 20px;
    align-self: center;
    margin-top: 5px;
  }

  .notification {
    margin-right: 23px;
    align-self: center;
  }
}

.sidenav {
  padding: 20px;
}

.vertical-menu {
  width: 250px;
  z-index: 1001;
  background: #ffffff;
  bottom: 0;
  margin-top: 0;
  position: absolute;
  top: 70px;
  // -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  // box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-right: inset;
  overflow: auto;

  ul.MuiList-root {
    padding: 0;
  }
}

.main-content {
  margin-left: 250px;
  height: 90vh;
  overflow: auto;
}

.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }
  .main-content {
    margin-right: 0 !important;
  }
  body.sidebar-enable .vertical-menu {
    display: block;
  }
}
