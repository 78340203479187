.notification {
    font-family: Poppins !important;
    .leftGrid {
        margin: 40px;
    }

    .rightGrid {
        margin-left: 10%;
        margin-top: 5%;
        .title,
        .selectUser,
        .userSelection,
        .desc,
        .options,
        .submit{
            margin-bottom: 20px;
        }

        .options{
            #option{
                margin-bottom: 5px;
            }
            .radioBtns{
                display: flex;
                .p-radiobutton.p-component{
                    margin-right: 5px;
                }
                .emailOption{
                    margin-right: 3%;
                }
            }
        }

        .p-multiselect{
            background: #F2F2F2;
            width: 450px;
            font-size: 16px !important;
            font-family: Poppins !important;
            height: 45px !important;
        }

        .p-inputwrapper{
            background: #F2F2F2;
            width: 450px;
            font-size: 12px;
            font-family: Poppins !important;
        }

        label{
            font-family: Poppins !important;
        }

        .title input {
            padding: 9px;
            background: #F2F2F2;
            width: 450px;
            font-size: 16px;
            font-family: Poppins !important;
        }
        .desc textarea{
            padding: 9px;
            background: #F2F2F2;
            width: 450px;
            font-size: 16px;
            font-family: Poppins !important;
        }
    }
    .addBtn{
        font-family: Poppins !important;
        background-color: #F3C300;
    }
}