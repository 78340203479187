.division,
.sessions,
.regions,
.location,
.projType,
.waste,
.serviceArea {
  .topSection {
    margin-bottom: 5px;

    .rightSide {
      margin-right: 70px;
      margin-top: 23px;
    }
  }
}

.sessions,
.waste,
.division,
.location,
.projType,
.regions,
.serviceArea {
  #table {
    margin-top: 2%;
    .exportBtn{
      margin-top: -75px;
    }
  }
}

// .sessions {
//   #table div[data-field="sessions"] .MuiDataGrid-columnHeaderTitleContainer,
//   #table div[data-field="from"] .MuiDataGrid-columnHeaderTitleContainer,
//   #table div[data-field="to"] .MuiDataGrid-columnHeaderTitleContainer {
//     margin-left: 45px;
//   }
// }

.sessions,
.waste,
.division,
.location,
.projType,
.regions {
  #table div[data-field="action"] .MuiBox-root {
    gap: unset;
    margin-left: 0;
  }
}

.add-modal-master .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  .serviceAreaText {
    margin-bottom: 5px;
  }

  button {
    font-family: Poppins;
    width: 120px;
    background-color: #f3c300;
  }

  .role-add {
    margin-bottom: 10px;

    .wt {
      margin-bottom: 10px;
    }

    label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 350px;
      font-size: 12px;
      font-family: Poppins;
    }
  }

  .proj-text-latlng {
    display: flex;
    margin-bottom: 10px;

    .lat,
    .lng {
      margin-right: 10px;
    }

    label {
      color: #000;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 160px;
      font-size: 12px;
      font-family: Poppins;
    }
  }

  .proj-text-1,
  .proj-text-2 {
    margin-bottom: 10px;

    label {
      color: #000;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 350px;
      font-size: 12px;
      font-family: Poppins;
    }
  }
  .proj-text-2 {
    display: flex;
    .from-text,
    .to-text {
      margin-right: 10px;
    }
    input {
      width: 160px;
    }
  }
}

.block-modal-master .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  text-align: center;
  padding: 10px;

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -5px;
    margin-right: -5px;
  }

  button {
    font-family: Poppins;
    background-color: #f3c300;
  }

  .infoIcon {
    color: #00895f;
    width: 38px;
    height: 38px;
  }

  h4,
  p {
    margin: 0;
    margin-bottom: 10px;
  }

  .action-buttons {
    button {
      margin-right: 5px;
      border-radius: 0;
      padding: 10px;
      width: 100px;
    }
  }
}
