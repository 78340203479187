.App {
  text-align: center;
  font-family: Poppins !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#table {
  .MuiDataGrid-columnHeaderWrapper {
    background-color: #00895f;
    color: white;

    svg {
      fill: white;
    }

    .MuiDataGrid-menuIcon{
      .MuiIconButton-label svg{
        margin-left: -18px;
        margin-top: 5px;
      }
    }
  }

  .MuiDataGrid-root {
    font-family: Poppins !important;
  }

  .MuiDataGrid-root
    .MuiDataGrid-columnHeader--alignCenter
    .MuiDataGrid-columnHeaderTitleContainer {
    // justify-content: center;
    margin-left: 0px !important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    // justify-content: center;
    // margin-left: 30px;
    // padding: 0;
    overflow: unset;
    min-width: unset;
    // align-items: unset;
  }

  .MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    // text-align: center;
    font-size: 13px;
    letter-spacing: normal;
    // justify-content: center;
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable {
    padding: 4px;
  }

  div[data-field="action"] {
    // padding: 0;

    .MuiBox-root {
      gap: unset;
      margin-left: 55px;
    }
  }

  .edit {
    background-color: #27a1584d;
    border-radius: 20%;
    margin-right: 5px;
    height: 30px;
    width: 30px;
    padding: 5px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .delete {
    background-color: #f116164d;
    border-radius: 20%;
    margin-right: 5px;
    height: 30px;
    width: 30px;
    padding: 5px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .exportBtn {
    float: right;
    margin-top: -65px;
    .MuiButton-label a,
    .downloadIcon {
      color: white;
      text-decoration: none;
    }
  }
}

#menu- {
  ul li {
    font-size: 13px;
    font-family: Poppins !important;
  }
}

.alignright {
  margin-bottom: 20px;
  text-align: end;
}
