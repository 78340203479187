.supervisor {
  padding: 10px;

  .imageLink{
    cursor: pointer;
    color: #00895F ;
    display: flex;
    .launchIcon{
      margin-top: 5px;
    }
  }
  .topSection {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .addBtn {
      background-color: #f3c300;
      width: 120px;
    }

    .leftside {
      display: flex;
    }
    .rightSide {
      margin-top: 20px;
      margin-left: 45px;
    }
  }

  .dropdowns,
  .search {
    margin-right: 10px;

    div#demo-simple-select {
      width: 100px;
      padding: 5px;
      background-color: #f2f2f2;
      font-size: 13px;
    }

    label#demo-simple-select-label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    #outlined-basic {
      padding: 7px;
      background-color: #f2f2f2;
      font-size: 13px;
    }
  }

  #table div[data-field="action"] .MuiBox-root {
    gap: unset;
    margin-left: 0px;
  }
}

.add-modal-supervisor .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  button {
    font-family: Poppins;
    width: 120px;
    background-color: #f3c300;
  }

  .userid,
  .name,
  .mgrid,
  .email,
  .password,
  .contact,
  .image,
  .address {
    margin-right: 10px;
  }

  .role-add {
    margin-bottom: 10px;

    .wt {
      margin-bottom: 10px;
    }

    label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 150px;
      font-size: 12px;
      font-family: Poppins;
    }
  }

  .proj-text-1,
  .proj-text-2 {
    margin-bottom: 10px;
    display: flex;

    label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 150px;
      font-size: 12px;
      font-family: Poppins;
    }
  }
  .proj-text-2 {
    display: flex;
    input {
      width: 150px;
    }
  }
}

.block-modal-supervisor .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  text-align: center;
  padding: 10px;

  button {
    font-family: Poppins;
    background-color: #f3c300;
  }

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -5px;
    margin-right: -5px;
  }

  .infoIcon {
    color: #00895f;
    width: 38px;
    height: 38px;
  }

  h4,
  p {
    margin: 0;
    margin-bottom: 10px;
  }

  .action-buttons {
    button {
      margin-right: 5px;
      border-radius: 0;
      padding: 10px;
      width: 100px;
    }
  }
}
