.wasteRecord {
  padding: 10px;

  .exportBtn {
    float: right;
    margin-top: -50px;
    background-color: white;
    border: 1px solid white;
    padding: 0;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background-color: #00895f;
    color: white;
    font-family: Poppins;
    font-size: 0.875rem;
  }

  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: white;
  }
}
