.userRoles {
  #table div[data-field="action"] .MuiBox-root {
    gap: unset;
    margin-left: 0px;
    .editpermit {
      color: firebrick;
      background-color: lightgray;
      border-radius: 20%;
      margin-right: 5px;
      height: 25px;
      width: 25px;
      padding: 5px;
      svg{
        height: 20px;
        width: 20px;
      }
    }
  }

  .rightSide {
    margin-right: 70px;
  }
}

.userList {
  #table div[data-field="action"] .MuiBox-root {
    gap: unset;
    // margin-left: 12px;
    margin-left: 0px;
  }

  #table div[data-field="status"],
  #table div[data-field="created"] {
    .MuiDataGrid-columnHeaderTitleContainer {
      margin-left: 13px !important;
    }
  }

  .showby {
    margin-left: 5px;
  }

  .rightSide {
    margin-right: 70px;
  }
}

.block-modal .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  text-align: center;
  padding: 10px;

  button {
    font-family: Poppins;
  }

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -5px;
    margin-right: -5px;
  }

  .infoIcon {
    color: #00895f;
    width: 38px;
    height: 38px;
  }

  h4,
  p {
    margin: 0;
    margin-bottom: 10px;
  }

  .action-buttons {
    button {
      margin-right: 5px;
      border-radius: 0;
      padding: 10px;
      width: 100px;
    }
  }
}

.add-modal .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 53%;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  button {
    font-family: Poppins;
    width: 120px;
  }

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  .rightGrid {
    .userName,
    .adminName,
    .selectRole,
    .mail-no,
    .submit,
    .phno {
      margin-bottom: 20px;
    }

    .userName, .adminName, .selectRole, .submit, .phno{
      .MuiTextField-root{
        width: 80%;
      }      
    }

    .userName input,
    .adminName input, .phno input {
      padding: 10px;
      background: #f2f2f2;
      width: 100%;
      font-size: 12px;
      font-family: Poppins;
    }

    label#demo-simple-select-label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      /* 133.28% */
    }

    .selectRole{
      .MuiInputBase-root{
        width: 80%;
      }
    }

    .selectRole div#demo-simple-select {
      padding: 10px;
      background: #f2f2f2;
      width: 100%;
      font-size: 12px;
      font-family: Poppins;
    }

    .mail-no {
      display: flex;
      width: 80%;

      .mail {
        margin-right: 10px;
        width: 100%;
        .MuiTextField-root{
          width: 100%;
        }
      }

      .num{
        width: 100%;
        .MuiTextField-root{
          width: 100%;
        }
      }

      .mail input,
      .num input {
        padding: 10px;
        background: #f2f2f2;
        width: 100%;
        font-size: 12px;
        font-family: Poppins;
      }
    }
  }
}

.add-modal-role .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 450px;
  overflow: auto;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  h5 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .permitTable,
  .editPermitTable {
    max-height: 275px;
    overflow-y: auto;
    overflow-x: hidden;
    table {
      font-size: 12px;
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      th {
        background-color: #00895f;
      }
      tr {
        background-color: #f2f2f2;
      }
      tr,
      td,
      th {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: center;
        input {
          width: 100px;
        }
      }
    }
  }

  .modalAlert{
    button{
      width: auto;
    }
  }

  .editPermitTable{
    max-height: 385px;
  }

  button {
    font-family: Poppins;
    width: 120px;
  }

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  .role-add {
    margin-bottom: 25px;

    label {
      color: #000;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 350px;
      font-size: 12px;
      font-family: Poppins;
    }
  }
}

.edit-modal-role .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  overflow: auto;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  button {
    font-family: Poppins;
    width: 120px;
  }

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  .role-add {
    margin-bottom: 15px;

    label {
      color: #000;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 260px;
      font-size: 12px;
      font-family: Poppins;
    }
  }
}


.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
