@import url("https://fonts.googleapis.com/css?family=Poppins");

.loginPage {
  display: table;
  height: 100%;
  margin: 0 auto;
  margin-top: 60px;

  .topSection {
    text-align: center;
  }

  .logoIcon {
    height: 70px;
    width: 130px;
  }

  h2 {
    margin: 0;
    color: #181e25;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }

  p {
    color: #788ba5;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.992px;
  }

  .formSection {
    margin-top: 20px;

    p {
      margin-bottom: 5px;
      justify-content: space-between;
      display: flex;
      margin-top: 15px;
      color: black;
    }

    input#outlined-basic\ email,
    input#outlined-basic\ password {
      height: 20px;
      padding: 9.5px 13px;
      width: 30em;
      font-family: "Poppins";
      text-align-last: left;
      font-size: 12px;
    }

    .btn {
      margin-top: 20px;

      .loginBtn {
        width: 28em;
        color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.992px;
        height: 40px;
        min-height: 24px;
        padding: 9.01px 150px 10.99px 150px;
        text-transform: none;
      }
    }

    .eyeIcon {
      position: absolute;
      margin-left: -30px;
      margin-top: 8px;
    }

    .eyeIconRed {
      position: absolute;
      margin-left: -30px;
      margin-top: 8px;
      filter: hue-rotate(545deg) saturate(525%);
    }

    .fgtPwd {
      text-decoration: none;
      font-size: 10px;
    }

    .pwdError {
      font-size: 10px;
      color: #00895f;
    }

    .errorMsg {
      font-size: 15px;
      color: red;
      text-align: center;
      margin: 10px;
    }
  }
  span.MuiLoadingButton-loadingIndicator {
    left: 63%;
    color: #fff;
  }
}

.logOut {
  text-align: center;
  margin: 5%;
  h5 {
    color: green;
    a {
      padding-left: 10px;
    }
  }
}
