.banner {
  padding: 10px;
  .imageLink {
    cursor: pointer;
    color: #00895f;
    display: flex;
    .launchIcon {
      margin-top: 5px;
    }
  }
  .primeSwitch[aria-checked="true"] {
    .p-inputswitch-slider {
      background: #00895f;
    }
  }
  .topSection {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .addBtn {
      background-color: #f3c300;
      width: 120px;
    }

    .leftside {
      display: flex;
    }
    .rightSide {
      margin-top: 29px;
      margin-right: 70px;
    }
  }

  #table {
    margin-top: 2%;
    .exportBtn{
      margin-top: -75px;
    }
    div[data-field="action"] .MuiBox-root {
      gap: unset;
      margin-left: 0;
    }
  }
}

.add-modal-banner .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  .radioBtn {
    display: flex;
    .homeRadio,
    .surveyRadio {
      display: flex;
      > span {
        padding: 0;
      }
      p {
        margin: 8px;
        font-size: 12px;
      }
    }
  }

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  .MuiAlert-root {
    margin-bottom: 10px;
  }

  .submitBtn {
    font-family: Poppins;
    width: 120px;
    background-color: #f3c300;
  }

  .role-add {
    margin-bottom: 10px;

    .MuiTextField-root {
      margin-bottom: 10px;
    }

    .wt {
      margin-bottom: 10px;
    }

    label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 300px;
      font-size: 12px;
      font-family: Poppins;
    }
  }

  .proj-text-1,
  .proj-text-2 {
    margin-bottom: 10px;

    label {
      color: #000;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 350px;
      font-size: 12px;
      font-family: Poppins;
    }
  }
  .proj-text-2 {
    display: flex;
    .from-text,
    .to-text {
      margin-right: 10px;
    }
    input {
      width: 160px;
    }
  }
}

.add-modal-image .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1300px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  img {
    margin: 10px;
  }
}

.block-modal-banner .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  text-align: center;
  padding: 10px;

  button {
    font-family: Poppins;
  }

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -5px;
    margin-right: -5px;
  }

  .infoIcon {
    color: #00895f;
    width: 38px;
    height: 38px;
  }

  h4,
  p {
    margin: 0;
    margin-bottom: 10px;
  }

  .action-buttons {
    button {
      margin-right: 5px;
      border-radius: 0;
      padding: 10px;
      width: 100px;
      background-color: #f3c300;
    }
  }
}
