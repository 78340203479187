.survey {
  padding: 10px;

  .topSection {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .addBtn, .qrBtn {
      background-color: #f3c300;
      width: 120px;
      margin-right: 10px;
    }

    .leftside {
      display: flex;
    }
    .rightSide {
      margin-top: 20px;
      margin-left: 60px;
    }
  }

  .projCodeLink {
    text-decoration: none;
  }

  .dropdowns,
  .search {
    margin-right: 10px;

    div#demo-simple-select {
      width: 100px;
      padding: 5px;
      background-color: #f2f2f2;
      font-size: 13px;
    }

    label#demo-simple-select-label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    #outlined-basic {
      padding: 7px;
      background-color: #f2f2f2;
      font-size: 13px;
    }
  }

  #table div[data-field="action"] .MuiBox-root {
    gap: unset;
    margin-left: 0px;
  }
}

.add-modal-survey.edit .modal-Box {
  width: 77%;
  .question,
  .description {
    textarea {
      width: 180px !important;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
      padding: 10px;
      background: #f2f2f2;
      font-size: 12px;
      font-family: Poppins;
    }
  }
}

.add-modal-survey .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  button {
    font-family: Poppins;
    width: 120px;
    background-color: #f3c300;
  }

  .MuiAlert-root {
    button[title="Close"] {
      width: auto;
      background-color: white;
    }
  }

  .projectName{
    margin-right: 10px;
  }

  .projectCode,
  .areaCode,
  .enabled {
    div#demo-simple-select {
      padding: 7px !important;
    }
  }

  .dropdowns,
  .projectCode,
  .areaCode,
  .enabled {
    margin-right: 10px;

    div#demo-simple-select {
      width: 150px;
      padding: 5px;
      background-color: #f2f2f2;
      font-size: 13px;
    }

    label#demo-simple-select-label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    #outlined-basic {
      padding: 7px;
      background-color: #f2f2f2;
      font-size: 13px;
    }
  }

  .question,
  .description,
  .yesno,
  .options,
  .enabled,
  .from,
  .to,
  .fromDate,
  .toDate,
  .projectCode,
  .areaCode,
  .enabled {
    margin-right: 10px;
  }

  .role-add {
    margin-bottom: 10px;

    .wt {
      margin-bottom: 10px;
    }

    label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 200px;
      font-size: 12px;
      font-family: Poppins;
    }
  }

  .question,
  .description {
    textarea {
      width: 200px !important;
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
      padding: 10px;
      background: #f2f2f2;
      font-size: 12px;
      font-family: Poppins;
    }
  }

  .proj-text-1,
  .proj-text-2 {
    margin-bottom: 10px;
    display: flex;

    label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 200px;
      font-size: 12px;
      font-family: Poppins;
    }
  }
  .proj-text-2 {
    display: flex;
    display: flex;

    .from-text,
    .to-text {
      margin-right: 10px;
    }

    display: flex;

    .from-text,
    .to-text {
      margin-right: 10px;
    }

    input {
      width: 145px;
    }
  }
}

.survey-record {
  padding: 10px;
  font-family: Poppins;
  .filters {
    display: flex;
    margin-bottom: 3%;
    p {
      margin: 0px;
      margin-bottom: 5px;
      font-family: Poppins;
    }
    .fromDate,
    .toDate {
      margin-right: 2%;
      button.p-datepicker-trigger {
        background-color: #f3c300;
        border: 1px solid #f3c300;
        font-family: Poppins;
      }
    }
    .button {
      margin-top: auto;
      width: 250px;
      text-align: end;
      button {
        margin-right: 5%;
        background-color: #f3c300;
        border: 1px solid #f3c300;
      }
    }
  }
  
  .exportBtn {
    float: right;
    margin-top: -50px;
    background-color: white;
    border: 1px solid white;
    padding: 0;
  }

  table {
    .p-datatable-thead > tr > th {
      background-color: #00895f;
      color: white;
      font-family: Poppins;
    }
    tbody td{
      font-family: Poppins;
    }
  }
}
