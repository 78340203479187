.projects {
  padding: 10px;

  input {
    font-family: Poppins;
  }

  h3 {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 19.992px;
  }

  .topSection {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .addBtn {
      background-color: #f3c300;
      width: 120px;
      border-radius: unset;
    }

    .leftside {
      display: flex;
    }

    .rightSide {
      margin-top: 20px;
    }
  }

  .dropdowns,
  .search {
    margin-right: 10px;

    div#demo-simple-select {
      width: 100px;
      padding: 5px;
      background-color: #f2f2f2;
      font-size: 13px;
      font-family: Poppins;
    }

    label#demo-simple-select-label {
      color: #000;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    #outlined-basic {
      padding: 7px;
      background-color: #f2f2f2;
      font-size: 13px;
    }
  }

  #table {
    div[data-field="action"] .MuiBox-root {
      gap: unset;
      margin-left: 0px;
    }

    .projCodeLink {
      text-decoration: none;
    }

    .edit {
      background-color: #27a1584d;
      border-radius: 20%;
      margin-right: 5px;
      height: 25px;
      width: 25px;
      padding: 5px;

      label {
        color: #000;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.992px;
        margin-bottom: 5px;
      }

      input {
        padding: 10px;
        background: #F2F2F2;
        width: 350px;
        font-size: 12px;
        font-family: Poppins;
      }

      #modal-select-project-code,
      #modal-select-project-type {
        padding: 10px;
        background: #F2F2F2;
        width: 350px;
        font-size: 12px;
        font-family: Poppins;
      }
    }

    .role-add-two-column-flex {
      display: flex;
      margin-bottom: 10px;

      .dropdowns {
        margin-right: 10px;
      }

      label {
        color: #000;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.992px;
        margin-bottom: 5px;
      }

      #modal-select-manager,
      #modal-select-region,
      #modal-select-location,
      #modal-select-supervisor {
        width: 170px;
        padding: 5px;
        background-color: #F2F2F2;
        font-size: 13px;
        font-family: Poppins;
      }
    }

    .delete {
      background-color: #f116164d;
      border-radius: 20%;
      margin-right: 5px;
      height: 25px;
      width: 25px;
      padding: 5px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .block {
      background-color: #bf252233;
      border-radius: 20%;
      margin-right: 5px;
      height: 25px;
      width: 25px;
      padding: 5px;

      svg {
        width: 20px;
        height: 20px;
        color: #bf2522;
      }
    }
  }
}

.add-modal-project .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  padding: 20px;
  font-family: Poppins;

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -10px;
    margin-right: -5px;
  }

  .proj-dropdowns-1 {
    display: flex;
    margin-bottom: 10px;
  }

  .proj-dropdowns-2 {
    display: flex;
    margin-bottom: 10px;
  }

  .projType,
  .region,
  .location,
  .division,
  .servicearea {
    margin-right: 10px;
  }

  div#demo-simple-select {
    width: 107px;
    padding: 5px;
    background-color: #f2f2f2;
    font-size: 13px;
    font-family: Poppins;
  }

  label#demo-simple-select-label {
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.992px;
    margin-bottom: 5px;
  }

  button {
    font-family: Poppins;
    width: 120px;
  }

  .role-add {
    margin-bottom: 10px;

    label {
      color: #000;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    input {
      padding: 10px;
      background: #f2f2f2;
      width: 400px;
      font-size: 12px;
      font-family: Poppins;
    }

    #modal-select-project-code,
    #modal-select-project-type {
      padding: 10px;
      background: #F2F2F2;
      width: 400px;
      font-size: 12px;
      font-family: Poppins;
    }
  }

  .role-add-two-column-flex {
    display: flex;
    margin-bottom: 10px;

    .dropdowns {
      margin-right: 10px;
    }

    label {
      color: #000;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.992px;
      margin-bottom: 5px;
    }

    #modal-select-manager,
    #modal-select-region,
    #modal-select-location,
    #modal-select-supervisor {
      width: 195px;
      padding: 5px;
      background-color: #F2F2F2;
      font-size: 13px;
      font-family: Poppins;
    }
  }
}

.block-modal-project .modal-Box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  background-color: white;
  border: 2px solid;
  border-color: white;
  text-align: center;
  padding: 10px;

  .closeIcon {
    float: right;
    cursor: pointer;
    margin-top: -5px;
    margin-right: -5px;
  }

  button {
    font-family: Poppins;
  }

  .infoIcon {
    color: #00895f;
    width: 38px;
    height: 38px;
  }

  h4,
  p {
    margin: 0;
    margin-bottom: 10px;
  }

  .action-buttons {
    button {
      margin-right: 5px;
      border-radius: 0;
      padding: 10px;
      width: 100px;
    }
  }
}