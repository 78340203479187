body {
  overflow: auto;
}
.feedback {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  
  .logoIcon {
    height: 70px;
    width: 130px;
  }
  .feedbackSection {
    .ques1,
    .ques2,
    .ques3,
    .ques4,
    .ques5,
    .ques6,
    .comments {
      text-align: left;
      margin-left: auto;
      .MuiFormControl-root {
        margin-left: 30px;
      }
    }
    .ques5,
    .ques6,
    .comments {
      .text-area {
        width: 75%;
      }
    }
    .submitBtn {
      background-color: #f3c300;
      padding: 10px;
      font-size: 20px;
      margin-top: 30px;
      margin-left: 7%;
    }
  }
}

.questionSurvey {
  font-size: 22px;
}

.surveyQuestions {
  text-align: center;
  .topSection {
    display: flex;
    h3 {
      margin: 0;
      align-self: center;
      color: #00895f;
    }
  }
  .banner {
    .p-carousel-indicator.p-highlight {
      .p-link {
        background-color: #00895f;
      }
    }
    .bannerImage {
      width: 100%;
      height: 350px;
    }
  }
  .questionSection {
    margin: 10px;
    margin-left: 4%;
    .smileyList {
      img {
        cursor: pointer;
        width: 60px;
      }
    }
  }
  .submitBtn {
    margin-top: 3%;
    button {
      background-color: #f3c300;
      border: 1px solid #f3c300;
    }
  }
}

.termsCls {
  margin-top: 20px;
    text-align: left;
}

.radio {
  margin-right: 15px;
}
