.dashboard-waste {
  margin: 10px;
  white-space: nowrap;
  .topsection {
    margin-bottom: 40px;
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    .totalwaste {
      width: 30%;
      margin-right: 40px;
      background-color: aliceblue;
      height: 190px;
      .p-card-content {
        margin-left: 25px;
        display: flex;
      }
      .rightWaste {
        float: right;
        // margin-right: 50px;
        p {
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 0.5rem;
          color: #00895f;
        }
      }
    }
    .p-card-body {
      padding: 0px;
      text-align: center;
    }
  }
  .bottomsection {
    display: flex;
    .prod,
    .food,
    .store {
      width: 32%;
      margin-right: 20px;
      height: 170px;
      p {
        font-size: 1.5rem;
        font-weight: 600;
      }
      .p-card-content {
        padding: 0;
      }
    }
    .p-card-body {
      text-align: center;
    }
  }
  .totalwaste .p-card-title {
    color: #00895f;
  }
  .prod .p-card-title {
    color: blueviolet;
  }
  .food .p-card-title {
    color: chocolate;
  }
  .store .p-card-title {
    color: deeppink;
  }
  .filters {
    display: flex;
    margin-bottom: 3%;
    align-items: flex-end;
    .sar,
    .kg {
      margin-right: 10px;
      display: grid;
    }
    .p-button-info {
      background-color: #f3c300;
      border: 1px solid #f3c300;
    }
  }
}

.flex-disp {
  display: inline-flex;
}

.flex-disp-multi {
  max-width: 1000px !important;
}

.card-ex {
  margin: 15px;
  width: 235px;
  p.displayP{
    img{
      margin-right: 10px;
    }
  }
}

h3 {
  border-bottom: 1px solid black;
  text-align: center;
  margin: 0;
}

.dashboard-section {
  display: flex;
  .leftSec {
    .header-part {
      h1 {
        border-bottom: 1px solid black;
        text-align: center;
        margin: 0;
      }
      h3 {
        margin: 0;
        text-align: center;
        color: white;
        background-color: darkgrey;
      }
    }
    .body-part {
      h5 {
        color: white;
        margin: 0;
      }
      .value-waste,
      .weight-waste,
      .area-waste,
      .store-waste {
        h5 {
          background-color: olivedrab;
          margin-bottom: 10px;
          font-size: 20px;
          text-align: center;
        }
        .icon {
          display: flex;
          height: 100px;
          img {
            width: 150px;
            height: 150px;
            margin-right: 100px;
          }
          p {
            font-size: 25px;
            font-weight: bold;
            text-align: center;
          }
        }
        .footer-text {
          border-bottom: 1px solid black;
          .arrow-icon {
            display: flex;
            justify-content: center;
            margin-left: 175px;
            svg {
              color: green;
            }
            p {
              margin: 0;
            }
          }
          p {
            text-align: center;
          }
        }
        .dis-text {
          margin: 20px;
          text-align: center;
        }
      }
      .weight-waste {
        h5 {
          background-color: royalblue;
          margin-bottom: 10px;
          font-size: 20px;
          text-align: center;
        }
      }
      .area-waste {
        h5 {
          background-color: darkorange;
          margin-bottom: 10px;
          font-size: 20px;
          text-align: center;
        }
      }
      .store-waste {
        h5 {
          background-color: darkgoldenrod;
          margin-bottom: 10px;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }

  .rightSec {
    h1 {
      border-bottom: 1px solid black;
      text-align: center;
      margin: 0;
    }
    .body-part {
      h5 {
        color: white;
        margin: 0;
        background-color: royalblue;
        margin-bottom: 10px;
        font-size: 20px;
        text-align: center;
      }
      div{
        text-align: center;
        border-bottom: 1px solid black;
        h2{
          color: olivedrab;
        }
      }
    }
    .footer-part{
      h5 {
        color: white;
        margin: 0;
        background-color: royalblue;
        margin-bottom: 10px;
        font-size: 20px;
        text-align: center;
        padding: 10px;
      }
      .conversion{
        text-align: center;
        >h2:nth-child(1){
          padding-bottom: 30px;
          border-bottom: 1px solid black;
        }
      }
    }
  }
}

.filter-sec {
  display: flex;
  justify-content: space-between;
}

.default-btn {
  display: flex;
  align-items: center;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-10 {
  margin-top: 10px
}

@media print {
  body * {
    visibility: hidden;
  }

  #printable-section,
  #printable-section * {
    visibility: visible;
  }

  #printable-section {
    position: absolute;
    left: 'center';
    top: 'center';
    width: 100%;
  }

  img {
    visibility: hidden;
  }
}

.displayP {
  display: flex;
  align-items: center;
}